@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);

html {
  background-color: #222831;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#root {
  background-color: #222831;
  color: aliceblue;
  height: 100%;
}


h1 {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 700;
}

h6 {
  color: #46b5d1;
  padding-bottom: 1em;

}

h2 {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 400;
  padding-bottom: 1em;

}

p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
}

.fab-icon {
  margin: 25px !important;
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
}

.nav-numbers {
  color: #46b5d1;
  font-family: 'IBM Plex Mono', monospace;
}


p {
    font-size: 20px;
}
.footer {
    bottom: 0 !important;
}
.project {
    display: block !important;
    margin: auto;
}
.repo-chips {
    display: flex;
    flex-wrap: wrap;
}
.chip {
    margin-left: 5px !important;
}

.repo-image {
    display: block !important;
    margin: auto;
    border: 5px solid #46b5d1;
    border-radius: 50%;
    padding: 5px;
}

.repo-owner {
    text-align: center;
}
